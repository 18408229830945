import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainMenu from '../../pages/MainMenu';
import WelcomePage from './WelcomePage';
import { pageMapper } from './pageMapper';
import './../../components/menu/menu.css';
import { Row, Col, Modal, Button } from 'react-bootstrap';

document.body.style = 'background: rgb(244, 244, 244);';

export default function MainContent(props) {
	const [showSidebar, setShowSidebar] = React.useState();

	const handleToggleSideBar = () => {
		setShowSidebar(!showSidebar);
	};

	if (props.menuPages) {
		// if (props.menuPages.length === 0) {
		// 	return (
		// 		<div style={overallStyle}>
		// 			<Header firstName={props.auth.user.user} />
		// 			<div style={appStyle}>
		// 				<p>No pages are currently available for your user type</p>
		// 			</div>
		// 			<Footer />
		// 		</div>
		// 	);
		// }
		return (
			<div style={{ backgroundColor: '#F4F4F4' }}>
				<link
					rel="stylesheet"
					href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
					integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
					crossOrigin="anonymous"
				/>

				<Row className="m-3">
					<Col id="sidebar-wrapper">
						<MainMenu
							menuPages={props.menuPages}
							pageMapper={pageMapper}
							showSidebar={showSidebar}
							handleToggleSideBar={handleToggleSideBar}
							signOutState={props.signOutFunc}
						/>
					</Col>
					<Col className="col-10 mt-3">
						<Row>
							{/* {props.inactive &&  */}
							<Modal
								show={props.inactive}
								size="lg"
								// onHide={() => setShow(false)}
								centered
								backdrop="static"
								keyboard={false}
							>
								<Modal.Header>
									<Modal.Title>Inactive</Modal.Title>
								</Modal.Header>

								<Modal.Body>
									<p>
										You have been away from the Maccabi
										Games webapp for more than 15 minutes.
										Refresh your screen to see the latest
										changes
									</p>
								</Modal.Body>

								<Modal.Footer>
									{/* <Button variant="secondary">Close</Button> */}
									<Button
										variant="primary"
										onClick={() =>
											window.location.reload(false)
										}
									>
										Refresh
									</Button>
								</Modal.Footer>
							</Modal>
							{/* } */}
							<Routes id="page-content-wrapper">
								{props.menuPages !== 'error' &&
									!Object.hasOwn(props.menuPages, 'error') &&
									props.menuPages.map(cardProps => {
										if (!pageMapper[cardProps.url]) {
											console.error(
												'The following url mapping does not exist in react',
												cardProps.url
											);
											return null;
										} else {
											return (
												<Route
													key={`route-${cardProps.pageTitle}`}
													exact
													path={`/${cardProps.url}`}
													element={
														pageMapper[
															cardProps.url
														].component
													}
												/>
											);
										}
									})}
								<Route path="*" element={<WelcomePage />} />
							</Routes>
						</Row>
					</Col>
				</Row>
			</div>
		);
	} else return null;
}
