import React from 'react';
import { connect } from 'react-redux';
import {
	catgendercombos,
	getplayers,
	assignplayer,
	unassignplayer,
	unassignall,
	assignall
} from '../services/teamassignments/teamassignmentsActions.js';
import ToggleSelector from '../components/general/ToggleSelector.js';
import SearchableDropdown from '../components/general/SearchableDropdown.js';
import UnassignedPlayersArea from '../components/teamassignments/UnassignedPlayersArea.js';
import DragDropItem from '../components/general/DragDropItem.js';
import AssignedPlayersArea from '../components/teamassignments/AssignedPlayersArea.js';
import { Container, Card, ListGroup, Button, Row } from 'react-bootstrap';
const d3 = require('d3-array');

class TeamAssignmentView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCountry: '',
			selectedCategory: '',
			numTeams: '1'
		};

		this.selectCountry = this.selectCountry.bind(this);
		this.selectCategory = this.selectCategory.bind(this);
		this.selectNumberofTeams = this.selectNumberofTeams.bind(this);
		this.unassignAllHandler = this.unassignAllHandler.bind(this);
		this.assignAllHandler = this.assignAllHandler.bind(this);
		this.assignAllHandlerByGrade = this.assignAllHandlerByGrade.bind(this);

		this.assignPlayerHandler = this.assignPlayerHandler.bind(this);
		this.unassignPlayerHandler = this.unassignPlayerHandler.bind(this);
	}

	componentDidMount() {
		this.props.getCategoryGenderOptions().then(() => {
			let country = Object.keys(this.props.categoriesByCountry).sort()[0];
			let categoriesByCountry = this.props.categoriesByCountry[country];
			categoriesByCountry.sort((a, b) => (a.text < b.text ? -1 : 1));
			this.setState(
				{
					selectedCountry: country,
					selectedCategory: categoriesByCountry[0].text
				},
				() => {
					this.props.getPlayersinCatGenderCountry({
						catGender: this.state.selectedCategory,
						country: this.state.selectedCountry
					});
				}
			);
		});
	}

	assignPlayerHandler(input_obj) {
		this.props.assignPlayer({
			...input_obj,
			country: this.state.selectedCountry,
			catGender: this.state.selectedCategory
		});
	}

	unassignPlayerHandler(registrantid) {
		this.props.unassignPlayer({
			registrantid: registrantid,
			country: this.state.selectedCountry,
			catGender: this.state.selectedCategory
		});
	}

	unassignAllHandler() {
		this.props.unassignAll({
			catGender: this.state.selectedCategory,
			country: this.state.selectedCountry
		});
	}

	assignAllHandler() {
		// console.log("Calling Assign All Handler")
		let currentCatGender = this.props.catGenderOptions.find(
			x => `${x.category} ${x.gender}` === this.state.selectedCategory
		);

		this.props.assignAll({
			grade: false,
			category: currentCatGender.category,
			gender: currentCatGender.gender,
			country: this.state.selectedCountry,
			numTeams: this.state.numTeams
		});
	}

	assignAllHandlerByGrade() {
		// console.log("Calling Assign All Handler by grade")
		let currentCatGender = this.props.catGenderOptions.find(
			x => `${x.category} ${x.gender}` === this.state.selectedCategory
		);

		this.props.assignAll({
			grade: true,
			category: currentCatGender.category,
			gender: currentCatGender.gender,
			country: this.state.selectedCountry,
			numTeams: this.state.numTeams
		});
	}

	selectCountry(event) {
		let country = event.target.innerHTML;
		let categoriesByCountry = this.props.categoriesByCountry[country];
		categoriesByCountry.sort((a, b) => (a.text < b.text ? -1 : 1));
		this.setState({
			selectedCountry: country,
			selectedCategory: categoriesByCountry[0].text
		});
		this.props.getPlayersinCatGenderCountry({
			catGender: this.props.categoriesByCountry[country][0].text,
			country: country
		});
	}

	selectCategory(event) {
		let category = event.target.innerHTML;
		this.setState({
			selectedCategory: category,
			numTeams: '1'
		});
		this.props.getPlayersinCatGenderCountry({
			catGender: category,
			country: this.state.selectedCountry
		});
	}

	selectNumberofTeams(event) {
		this.setState({ numTeams: event });
	}

	render() {
		if (this.props.categoriesByCountry) {
			if (this.state.selectedCountry !== '') {
				let countries = Object.keys(this.props.categoriesByCountry);
				countries.sort();
				let current = this.state.selectedCountry;
				let categories = this.props.categoriesByCountry[current];
				categories.sort((a, b) => (a.text < b.text ? -1 : 1));

				let currentPlayers = [];
				if (this.state.selectedCategory !== '') {
					currentPlayers = this.props.catGenderOptions.find(x => {
						return (
							x.country === this.state.selectedCountry &&
							`${x.category} ${x.gender}` ===
								this.state.selectedCategory
						);
					}).players;
				}

				const uniqueGrades = [
					...new Set(currentPlayers.map(item => item.grade))
				];
				const colorsGrades = ['yellow', '#90EE90', 'pink', 'orange'];
				let unassignedPlayers = currentPlayers.filter(
					x => x.team_id === null
				);

				unassignedPlayers = unassignedPlayers.map(x => {
					let grade = x.grade;
					let colorUser = uniqueGrades.indexOf(grade);
					let bold = x.gender === 'Male' ? true : false;
					return { ...x, colorUser: colorsGrades[colorUser], bold };
				});

				let assignedPlayers = currentPlayers.filter(
					x => x.team_id !== null
				);
				assignedPlayers = assignedPlayers.map(x => {
					let grade = x.grade;
					let colorUser = uniqueGrades.indexOf(grade);
					let bold = x.gender === 'Male' ? true : false;
					return { ...x, colorUser: colorsGrades[colorUser], bold };
				});

				let teamObjs = assignedPlayers.map(x => {
					return {
						team_id: x.team_id,
						name: x.teamname
					};
				});
				let teams = Array.from(new Set(teamObjs.map(x => x.team_id)));
				teams.sort();
				let numberOfTeamsRender;
				let buttonRender;
				if (assignedPlayers.length !== 0) {
					numberOfTeamsRender = (
						<div>
							<p>Number of Teams:</p>
							<p>{teams.length}</p>
						</div>
					);
					buttonRender = (
						<Button onClick={this.unassignAllHandler}>
							Unassign All
						</Button>
					);
				} else {
					if (this.state.selectedCategory !== 0) {
						let numTeams = categories.find(
							x => x.id === this.state.selectedCategory
						).number;
						let numTeamsArray = [
							...Array(parseInt(numTeams)).keys()
						];

						numberOfTeamsRender = (
							<SearchableDropdown
								itemsList={numTeamsArray.map(x => ({
									text: x + 1,
									id: x + 1
								}))}
								selectedItem={this.state.numTeams}
								selectItem={this.selectNumberofTeams}
								searchable={false}
							/>
						);
					}

					buttonRender = (
						<Row className="m-2">
							<div className="mb-2">
								<Button
									onClick={this.assignAllHandler}
									disabled={unassignedPlayers.length === 0}
								>
									Assign All
								</Button>
							</div>
							<div className="mb-2">
								<Button
									onClick={this.assignAllHandlerByGrade}
									disabled={unassignedPlayers.length === 0}
								>
									Assign By Grade
								</Button>
							</div>
						</Row>
					);
				}

				return (
					<div>
						<Card>
							<Card.Header
								style={{
									fontSize: 30,
									fontWeight: '500',
									color: '#4E73DF'
								}}
							>
								Team Assignment
							</Card.Header>
							<Card.Body>
								<Container>
									<Row>
										<ToggleSelector
											itemsList={countries}
											selectionObj={{
												selectedItem:
													this.state.selectedCountry,
												selectItem: this.selectCountry
											}}
										/>
										<ToggleSelector
											itemsList={categories.map(x => {
												return {
													text: x.text,
													textStyle:
														x.unassigned === 1
															? { color: 'red' }
															: {}
												};
											})}
											textStyle={true}
											selectionObj={{
												selectedItem:
													this.state.selectedCategory,
												selectItem: this.selectCategory
											}}
										/>
										{numberOfTeamsRender}
										{buttonRender}
										{/* </div> */}
									</Row>
									<Row>
										<UnassignedPlayersArea
											cardDropHandler={
												this.unassignPlayerHandler
											}
										>
											<ListGroup
												style={{
													minHeight: 200,
													maxHeight: 400,
													display: 'flex',
													flexDirection: 'column'
												}}
											>
												{unassignedPlayers.map(x => (
													<DragDropItem
														itemStyle={{
															backgroundColor:
																x.colorUser,
															fontWeight: x.bold
																? 'bold'
																: null
														}}
														key={x.registrant_id}
														id={x.registrant_id}
														text={x.name}
													/>
												))}
											</ListGroup>
										</UnassignedPlayersArea>
									</Row>
									<Row>
										<p className="text-center">Assigned</p>
										{/* AssignedPlayerArea component for each team */}
										{teams.map(x => {
											return (
												<ListGroup
													horizontal
													className="mb-3"
													style={{
														minHeight: 200,
														overflowX: 'hidden',
														maxWidth: '350px'
													}}
												>
													<AssignedPlayersArea
														teamID={x}
														key={x}
														teamName={
															teamObjs.find(
																z =>
																	z.team_id ===
																	x
															).team_id
														}
														cardDropHandler={
															this
																.assignPlayerHandler
														}
													>
														{assignedPlayers
															.filter(
																player =>
																	player.team_id ===
																	x
															)
															.map(y => (
																<DragDropItem
																	itemStyle={{
																		backgroundColor:
																			y.colorUser,
																		fontWeight:
																			y.bold
																				? 'bold'
																				: null,
																		height: '5.8vh',
																		alignItems:
																			'center',
																		paddingRight: 1,
																		paddingLeft: 1
																	}}
																	stylePBox={{
																		width: '65%'
																	}}
																	key={
																		y.registrant_id
																	}
																	id={
																		y.registrant_id
																	}
																	text={
																		y.name
																	}
																/>
															))}
													</AssignedPlayersArea>
												</ListGroup>
											);
										})}
									</Row>
								</Container>
							</Card.Body>
						</Card>
					</div>
				);
			} else {
				return <p>loading</p>;
			}
		} else {
			return <p>Loading</p>;
		}
	}
}

const mapStateToProps = state => {
	let raw = state.teamassignments;
	if (raw.catgenderoptions) {
		let groupedCategories = Object.fromEntries(
			d3.group(raw.catgenderoptions, x => x.country)
		);
		for (const country in groupedCategories) {
			groupedCategories[country] = groupedCategories[country].map(x => {
				return {
					text: `${x.category} ${x.gender}`,
					id: `${x.category} ${x.gender}`,
					number: x.num,
					...x
				};
			});
		}
		return {
			categoriesByCountry: groupedCategories,
			catGenderOptions: raw.catgenderoptions
		};
	} else {
		return {
			categoriesByCountry: null
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getCategoryGenderOptions: () => dispatch(catgendercombos()),
		getPlayersinCatGenderCountry: obj => dispatch(getplayers(obj)),
		assignPlayer: obj => dispatch(assignplayer(obj)),
		unassignPlayer: obj => dispatch(unassignplayer(obj)),
		unassignAll: obj => dispatch(unassignall(obj)),
		assignAll: obj => dispatch(assignall(obj))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAssignmentView);
